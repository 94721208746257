/**
 * Super class extended by event classes. Note that event classes
 * are singletons - ie. the file they're implemented in should
 * default export an instance of the class.
 */

import { isNil, get } from 'lodash';

import { store, tracker } from '../../config/instances';
import { REACT_APP } from '../../config/env';

// Things to record:
// - action (this should be passed over by each event)
// - user id
// - name
// - email
// - window.location.href
// - timestamp
// - user role
// - org (id, slug)
// - active inventory (id, url)
// - is mm admin account (connected to the user)
export const getDefaults = () => {
  const state = store.getState();

  // is MM admin connecting to another account
  const isConnectedMMAdmin =
    !isNil(get(state, 'admin.id')) &&
    !isNil(get(state, 'user.id')) &&
    get(state, 'admin.org.id') === REACT_APP.MM_ORG_ID &&
    get(state, 'user.org.id') !== REACT_APP.MM_ORG_ID;

  return {
    org: get(state, 'user.org.id'),
    orgSlug: get(state, 'user.org.slug'),
    orgSFDCAccountID: get(state, 'user.org.sfdcAccountId'),
    orgRecurlyAccountCode: get(state, 'user.org.recurlyAccountCode'),
    userId: get(state, 'user.id'),
    userName: get(state, 'user.fullName'),
    userEmail: get(state, 'user.email'),
    userOrgRole: get(state, 'user.orgRole'),
    defaultApplicationSerpCountry: get(
      state,
      'user.defaultApplicationSerpCountry',
    ),
    displayExactDistribution: get(state, 'user.displayExactDistribution'),
    activeInventoryId: get(state, 'filter.site.id'),
    activeInventoryUrl: get(state, 'filter.site.domain'),
    activeInventoryLevel: '',
    activeInventorySERPCountry: get(state, 'filter.site.defaultSerpCountry'),
    location: window.location.href,
    date: Date(),
    dateUnix: Date.now(),
    mmAdmin: isConnectedMMAdmin,
    mmAdminUserId: isConnectedMMAdmin ? get(state, 'admin.id', null) : '',
    mmAdminName: isConnectedMMAdmin ? get(state, 'admin.fullName', null) : '',
    mmAdminEmail: isConnectedMMAdmin ? get(state, 'admin.email', null) : '',
  };
};

class Event {
  // global record method
  record(action, data) {
    // generate an id to uniquely identify every event
    const eventId = Math.round(Math.random() * 100000000);

    // events should pass their action id
    if (isNil(action)) {
      throw new Error('Action parameter missing');
    }

    // if tracker not ready - ie. all agents are not initialized
    // log error and stop recording.
    if (!tracker) {
      console.warn('Failed to record event: tracker not ready');
      return;
    }

    // if `data` is not provided, it is likely that an there is
    // no implementation of the `record` method in an event class,
    // so this method was invoked directly. In that case, fail.
    if (!data || typeof data !== 'object') {
      console.warn(
        'Record attempt failed: ' +
          'No tracking data provided. It is most likely ' +
          'that an event class is missing implementation of the ' +
          'the `record` method.',
      );
      return;
    }

    // gather final track data
    const finalData = {
      action,
      eventId,
      ...getDefaults(),
      ...data,
      category: data.category || 'General',
    };

    // log
    console.log('🚨 Tracking:', finalData);

    // track event
    tracker.track(finalData);

    // track aggregate event
    tracker.track({ action: `Aggregate${finalData.category}` });
  }
}

export default Event;
