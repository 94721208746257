import { Suspense } from 'react';
import { MISC_SIMPLE_KEYS } from '@marketmuse/data-state/types';
import {
  useStoreDispatch,
  useStoreSelector,
} from '@marketmuse/data-state/hooks';
import { miscActions } from '@marketmuse/data-state/misc';
import { lazyLoad } from '@marketmuse/utilities';

import { toggleDynamic } from '../../actions/miscActions';
import CreateOrg from '../Admin/Orgs/CreateOrg';
import BriefCreateModal from '../Brief/BriefCreateModal';
import InventoryCsvModal from '../Inventory/components/InventoryCsvModal';
import CalendlyModal from '../Payments/CalendlyModal';

import BriefCancelModal from '../Brief/BriefCancelModal';
import UserLookup from '../../components/UserLookup/UserLookup';

const DocumentCreation = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'DocumentCreation' */ '@marketmuse/content-document-creation/DocumentCreation'
    ),
);

const AppModalsMiscState = () => {
  const dispatch = useStoreDispatch();
  const misc = useStoreSelector(state => state.misc);
  const documentsCreate = useStoreSelector(
    state => state.misc[MISC_SIMPLE_KEYS.documentsCreate],
  );

  return (
    <>
      {misc['uploadInventoryCsvModal'] && <InventoryCsvModal />}
      {misc['create-org'] && <CreateOrg />}
      {misc['create-brief-admin'] && (
        <BriefCreateModal
          close={() => dispatch(toggleDynamic({ id: 'create-brief-admin' }))}
        />
      )}

      {misc['briefCancellation'] && <BriefCancelModal />}
      {misc['userLookup'] && (
        <UserLookup
          close={() => dispatch(toggleDynamic({ id: 'userLookup' }))}
        />
      )}
      {misc['calendlyPremium'] && <CalendlyModal />}
      <Suspense fallback={<div></div>}>
        {documentsCreate?.isVisible && (
          <DocumentCreation
            items={documentsCreate.items}
            onClose={() => {
              dispatch(
                miscActions.setSimple({
                  key: MISC_SIMPLE_KEYS.documentsCreate,
                  value: {
                    isVisible: false,
                  },
                }),
              );
            }}
          />
        )}
      </Suspense>
    </>
  );
};

export default AppModalsMiscState;
